.userDropdown {
  display: none;
}

.userDropdownBtn:hover .userDropdown {
  display: block;
  display: flex;
  flex-direction: column;
  transition: all 1s;
}

.logo{
  width: auto;
  height: 50px;
}

/* Footer */

.container-footer{
  position: fixed; 
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: rgb(255, 255, 255);
  width: 100%;
}

.foot-img {
  display: block;
  margin: auto auto;
}

.foot-text {
  /* padding-top: 5px; */
  text-align: center;
}

.foot-text:hover {
  color: red;
}

.ad {
  height: 40px;
  margin-bottom: 10px;
  text-align: center;
  background-color: pink;
}
