@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
.userDropdown {
  display: none;
}

.userDropdownBtn:hover .userDropdown {
  display: block;
  display: flex;
  flex-direction: column;
  transition: all 1s;
}

.logo{
  width: auto;
  height: 50px;
}

/* Footer */

.container-footer{
  position: fixed; 
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: rgb(255, 255, 255);
  width: 100%;
}

.foot-img {
  display: block;
  margin: auto auto;
}

.foot-text {
  /* padding-top: 5px; */
  text-align: center;
}

.foot-text:hover {
  color: red;
}

.ad {
  height: 40px;
  margin-bottom: 10px;
  text-align: center;
  background-color: pink;
}

/* Header section */
.header {
  padding-bottom: 0;
}

.slider {
  -webkit-appearance: none;
  height: 12px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 75%;
  background: #b7791f;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 75%;
  background: #b7791f;
  cursor: pointer;
}

/*  CSS for Categories */

@media only screen and (max-width: 800px) {
  .category {
      /* padding-top: 5%;
      padding-bottom: 5%; */
      display: flex;
      overflow-x: auto;
  }

  .category::-webkit-scrollbar {
      width: 0;
  }

  .category .cat-item {
      min-width: 70px;
      line-height: 100px;
      text-align: center;
  }
  

  .cat-img {
      border-radius: 50%;
      width: 50%;
      display: block;
      margin: auto auto;
    }
  .cat-text {
    text-align: center;
    font-size: 12px;
  }
}

@media only screen and (min-width: 801px) {
  /* .category {
      padding-top: 5%;
      padding-bottom: 5%;
  } */

  .cat-img {
      border-radius: 50%;
      display: block;
      width: 50%;
      margin: auto auto;
  }
  .cat-text {
      text-align: center;
  }
}

.category {
  padding-top: 70px; 
}


.carousel-item {
  max-height: 150px;
  border-radius: 30px 30px 30px 30px;
  overflow: hidden;
  padding: 0 10px;
}

/* Featured Products */

/* .featured-product {
  height: 150px;
  width: 150px;
  margin-bottom: 100px;
} */

/* .recos {
  width: 18rem;
  padding: 50px 50px 50px 50px;
  display: inline-block;
} */

.product-header {
  display: inline-block;
  width: 100%;
}

.recommended-for-you {
  padding-left: 20px;
  float: left;
}

.view-all {
  padding-right: 20px;
  float: right;
}

.featured-products {
  display: inline-block;
  display: flex;
  overflow-x: auto;
  padding: 10px 10px 10px 10px;
}

.featured-products::-webkit-scrollbar {
  width: 0;
}

.product-container {
  max-width: 170px;
  margin-left: 3px;
  margin-right: 3px;
  min-height: 150px;
}

.featured-img {
  padding-top: 5px;
  height: 75px;
  width: 120px
}

.featured-text {
  padding-top: 40px;
}

/* All Products */

.all-products {
  margin-top: 10px;
}


/* Product images from server */


fieldset,
label {
  margin: 0;
  padding: 0;
}

/****** Style Star Rating Widget *****/

.rating {
  border: none;
  float: left;
}

.rating > input {
  display: none;
}

.rating > label:before {
  margin: 5px;
  font-size: 2.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\F005";
}

/* Half Rating System */

/*.rating > .half:before { 
  content: "\f089";
  position: absolute;
}
*/

/* 
<input type="radio" id="star4half" name="rating" defaultValue={4.5} /><label className="half" htmlFor="star4half" title="Pretty good - 4.5 stars" />

<input type="radio" id="star3half" name="rating" defaultValue={3.5} /><label className="half" htmlFor="star3half" title="Meh - 3.5 stars" />

<input type="radio" id="star2half" name="rating" defaultValue={3.0} /><label className="half" htmlFor="star2half" title="Kinda bad - 2.5 stars" />

<input type="radio" id="star1half" name="rating" defaultValue={2.5} /><label className="half" htmlFor="star1half" title="Meh - 1.5 stars" />

<input type="radio" id="starhalf" name="rating" defaultValue={0.5} /><label className="half" htmlFor="starhalf" title="Sucks big time - 0.5 stars" />
*/

.rating > label {
  color: #ddd;
  float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating>input:checked~label,
/* show gold star when clicked */
.rating:not(:checked)>label:hover,
/* hover current star */
.rating:not(:checked)>label:hover~label {
  color: #b7791f;
}

/* hover previous stars in list */

.rating>input:checked+label:hover,
/* hover current star when changing rating */
.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,
/* lighten current selection */
.rating>input:checked~label:hover~label {
  color: #b7791f;
}

/* css for the submenu at the top of the product description page  */

.submenu-container{
  padding-left: 40%;
  padding-top: 5%;
  padding-bottom: 5%;
}

/* css for the product details  */


.main-container{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 110px;

}

.main-container .big-img{
  max-width: 500px;
  min-width: 375px;
}

.big-img img{
  width: 100%;
  height: 100%;
  max-height: 400px;
  border-radius: 10px;
  padding-left: 2px;
  /* display: block; */
  /* object-fit: cover; */
}

.details .box{
  max-width: 98%;
  min-width: 290px;
  margin: 25px;
}

.box .row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}


.price-tag{
  padding-top: 5px;
  padding-left: 15px;
  display: inline;
  float: left;
}

.heading-tag{
  text-align: left;
}

.description-box{
  max-width: 500px;
  min-width: 375px;
  height: 50px;
}

.sellerInfo
{
  text-align: left;
  padding-bottom: 5px;
  padding-left: 4px;
}

/* css for the reveiw section  */

.review-icon{
  border-radius: 45%;
  display: inline;
  float: right;
  padding-right: 5px;
}

.review-tag{
  display: inline;
  float: left;
  padding-left: 5px;
}

/* The Modal */

.modalBackground{
  width: 100vw;
  height: 100vh;
  background-color: rgba(red, green, blue, alpha);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer{
  width: 50px;
  height: 50px;
  border-radius: 12px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.modalContainer .title{
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.modalContainer .body{
  flex: 50% 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.modalContainer .footer
{
  flex: 20% 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button{
  width: 10px;
  height: 10px;
  margin: 5px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 5px;
  font-size: 10px;
}

#cancelBtn{
  background-color: red;
}

/* The Close Button */
/* .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
} */

.reviewContainer{
  padding-top: 40px;
}

.carousel-item {
  max-height: 150px;
  border-radius: 15px;
  overflow: hidden;
  padding: 3px;
}

/* css for the featured section  */

.product-header {
  display: inline-block;
  width: 100%;
}

.recommended-for-you {
  padding-left: 20px;
  float: left;
}

.view-all {
  padding-right: 20px;
  float: right;
}

.featured-products {
  display: inline-block;
  display: flex;
  overflow-x: auto;
  padding: 10px 10px 10px 10px;
}

.featured-products::-webkit-scrollbar {
  width: 0;
}

.product-container {
  max-width: 170px;
  height: 120px;
  margin-left: 3px;
  margin-right: 3px;
}

/* .Featured-container{
  display: flex;
  overflow-x: scroll;
} */
/* this is the css for the first file of the productListing  */

.products {
    position: relative;
    width: 100%;
    padding-top: 110px;
}

/* this is css for the second file of the productlisting  */

.products2 {
    position: relative;
    width: 100%;
    padding-top: 20px;
}

/* this is the css of the product card  */

.container {
    display: grid;
    justify-content: center;
    border-radius: 10px;
    position: relative;
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    background-color:ghostwhite;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* to make the content visible at the bottom and not get hidden by the footer */
/* .main-container {
    padding-bottom: 150px;
    margin-left: 8px;
} */

/* to give left margin */
.first-container {
    padding-bottom: 150px;
    margin-left: 6px;
}

/* css for the part where we have ad  */

.carousel
{
    padding-top: 8px;
}

/* this is for the text to describe the product */

.text{
    display: inline;
    float: left;
    font-size: small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
/* this is the css for the prica tag  */

.tag{
    display: inline;
    float: right;
    font-size: small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* this is the css for the image of the product  */

.img{
    display: flex;
    justify-content: center;
}

